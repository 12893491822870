import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import axios from "axios"

import SEO from "../components/SEO"
import Heading from "../components/Heading"
import CheckoutBtn from "../components/CheckoutBtn"
import Button from "../components/Button"
import Image from "../components/Image"
// import CartUpsell from"../components/CartUpsell"
import Price from "../components/Price"
import CartFavorites from"../components/CartFavorites"

import { AppContext } from "../components/Context"

import styles from "./cart.module.scss"
import buttonStyles from "../components/button.module.scss"
import productStyles from "../templates/product.module.scss"

import cart from "../helpers/cart"
import favorites from "../helpers/favorites"

const Cart = ({ location }) => {
  const appState = useContext(AppContext)

  const [cartItems, setCartItems] = useState(cart.getCart())

  const cartCount = appState.cartCount = cart.getCartCount()
  let subtotal = (appState.currency === "USD") ?
    cartItems.reduce((acc, item) => (acc + parseInt(item.price, 10) * item.quantity), 0)
    : (appState.currency === "AED") ?
      cartItems.reduce((acc, item) => (acc + parseInt(item.intlPrices[0].node.price.amount, 10) * item.quantity), 0)
    : (appState.currency === "CAD") ?
      cartItems.reduce((acc, item) => (acc + parseInt(item.intlPrices[1].node.price.amount, 10) * item.quantity), 0)
    : (appState.currency === "EUR") ?
        cartItems.reduce((acc, item) => (acc + parseInt(item.intlPrices[2].node.price.amount, 10) * item.quantity), 0)
    : (appState.currency === "GBP") ?
      cartItems.reduce((acc, item) => (acc + parseInt(Math.ceil(item.intlPrices[3].node.price.amount), 10) * item.quantity), 0)
    : 0


  // Fix for empty cart render rehydration issue
  const [isClient, setClient] = useState(false)
  const key = isClient ? "client" : "server"

  useEffect(() => {
    setClient(true)
  }, [])

  useEffect(() => {
    let criteo_items = []
    cartItems.forEach(item => {
      criteo_items.push({
        id: item.productId,
        price: item.price,
        quantiy: item.quantity,
      })
    })
    window.criteo_q = window.criteo_q || []
    window.criteo_q.push(
      { event: "viewBasket", ecpplugin: "shopify", product: criteo_items}
    )
  }, [cartItems])

  // Initialize Affirm
  // useEffect(() => {
  //   if (window.affirm && window.affirm.ui)
  //     window.affirm.ui.ready(() => {
  //       window.affirm.ui.refresh()
  //     })
  // })

  const currencySymbol = (appState.currency === "EUR") ?
    "€"
    : (appState.currency === "GBP") ?
    "£"
    : (appState.currency === "AED") ?
    "د.إ"
    : (appState.currency === "CAD") ?
    "$"
    : "$"

  return (
    <>
      <SEO
        title="Cart"
        description={`Put description here`}
        url={location.href}
      />

      <Heading
        tag="h1"
        level="0"
        className={styles.heading}
      >
        Cart
      </Heading>

      <section className={styles.module} key={key}>
        {cartItems && cartItems.length > 0 &&
          <div>
            <ul className={styles.cart}>
              {cartItems.map((item, i) => (
                <CartItem
                  key={item.variantId}
                  item={item}
                  setCartItems={setCartItems}
                />
              ))}
            </ul>
            <div className={styles.subtotalWrapper}>
              <div className={styles.subtotalContainer}>
                <h4>Subtotal for {cartCount} {cartCount === 1 ? "item" : "items"}</h4>
                <p>{currencySymbol}{parseFloat(subtotal).toFixed(2).toLocaleString()}</p>
              </div>
              {appState.currency === "USD" &&
              <div className={styles.subtotalContainer}>
                <h4>Ground shipping within U.S.</h4>
                <p>Free</p>
              </div>
              }
              <CheckoutBtn
                showCartCount={true}
                className={styles.checkoutButton}
              />
              {/*<p className={`${styles.affirm} affirm-as-low-as`} data-page-type="cart" data-amount={parseInt(subtotal, 10) * 100} data-affirm-color="black" />*/}
              {/*<p className={styles.offer}>*/}
              {/*  Sale items are eligible for exchange only.*/}
              {/*</p>*/}
              {/*<p className={styles.offerRed}>*/}
              {/*  All discounted purchases are EXCHANGE ONLY, no refunds.*/}
              {/*</p>*/}
              <button
                className={styles.continueShopping}
                onClick={() => window.history.back()}
              >
                Continue shopping
              </button>
              {/*<p className={styles.chat}>*/}
              {/*  Have a question?<br />*/}
              {/*  <button onClick={() => appState.set({chatOpen: true})}>*/}
              {/*    Let's Talk*/}
              {/*  </button>*/}
              {/*</p>*/}
            </div>
            {/* <CartUpsell
              setCartItems={setCartItems}
            /> */}
            <CartFavorites />
          </div>
        }
        {cartItems && cartItems.length <= 0 &&
          <div className={styles.empty}>
            <p>Your cart is empty. Like your closet, it’ll look prettier with some new shoes in it.</p>
            <Button
              url="/collections/all"
            >
              Shop All
            </Button>
          </div>
        }
      </section>

      <Helmet>
        {/* Affirm */}
        <script async>{`
          var _affirm_config = {
            public_api_key: "XKI7FDVQ0VJSLQKZ",
            script: "https://cdn1.affirm.com/js/v2/affirm.js"
          };
          (function(l,g,m,e,a,f,b){var d,c=l[m]||{},h=document.createElement(f),n=document.getElementsByTagName(f)[0],k=function(a,b,c){return function(){a[b]._.push([c,arguments])}};c[e]=k(c,e,"set");d=c[e];c[a]={};c[a]._=[];d._=[];c[a][b]=k(c,a,b);a=0;for(b="set add save post open empty reset on off trigger ready setProduct".split(" ");a<b.length;a++)d[b[a]]=k(c,e,b[a]);a=0;for(b=["get","token","url","items"];a<b.length;a++)d[b[a]]=function(){};h.async=!0;h.src=g[f];n.parentNode.insertBefore(h,n);delete g[f];d(g);l[m]=c})(window,_affirm_config,"affirm","checkout","ui","script","ready");
        `}</script>
      </Helmet>
    </>
  )
}

export default Cart

const CartItem = ({ item, setCartItems }) => {
  const appState = useContext(AppContext)
  const [quantity, setQuantity] = useState(item.quantity)
  const [isFavorite, setIsFavorite] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  
  const [variants, setVariants] = useState([])
  const [sizeUpdate, setSizeUpdate] = useState("")
  const [quantityUpdate, setQuantityUpdate] = useState("")
  const [variantUpdate, setVariantUpdate] = useState({ quantityAvailable: item.maxQuantity })

  const updateCartCount = () => {
    appState.set({
      cartCount: cart.getCartCount(),
    })
  }

  const decrementQuantity = (e, amount = 1) => {
    if (quantity > 1) {
      cart.decrementQuantity(item, amount)
      setQuantity(quantity - 1)
    } else
      cart.removeItem(item)
    setCartItems(cart.getCart())
    updateCartCount()
  }

  const incrementQuantity = (e, amount = 1) => {
    if (quantity < item.maxQuantity || item.title === "Digital Gift Card") {
      cart.incrementQuantity(item, amount)
      setQuantity(quantity + 1)
      setCartItems(cart.getCart())
      updateCartCount()
    }
  }

  const changeQuantity = e => {
    if (e.target.value === "")
      return
    const value = parseInt(e.target.value, 10)
    if (value === 0)
      cart.removeItem(item)
    else if (value > item.maxQuantity || item.title === "Digital Gift Card")
      setQuantity(item.maxQuantity)
    else {
      if (value > quantity)
        cart.incrementQuantity(item, value - quantity)
      if (value < quantity)
        cart.decrementQuantity(item, quantity - value)
      setQuantity(value)
    }
    setCartItems(cart.getCart())
    updateCartCount()
  }

  const removeItem = () => {
    cart.removeItem(item)
    setCartItems(cart.getCart())
    updateCartCount()
  }

  const editItem = () => {
    setShowEdit(true)
    // get inventory from Shopify
    axios({
      url: "https://tamara-dev.myshopify.com/api/2021-04/graphql.json",
      method: "post",
      headers: {
        "X-Shopify-Storefront-Access-Token": "14ddd2b4c87fb6bc9c77e95cf3bed2c5",
      },
      data: {
        query: `
          query InventoryForSku {
            products(first: 10, query: "${item.productId}") {
              edges {
                node {
                  handle
                  variants(first: 100) {
                    edges {
                      node {
                        quantityAvailable
                        title
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        `,
      },
    })
      .then(result => {
        // Flatten variants from response for easier traversal
        let responseVariants = []
        result.data.data.products.edges.forEach(product => {
          product.node.variants.edges.forEach(variant => {
            responseVariants.push(variant.node)
          })
        })
        // Get variants for this item/sku
        const itemVariants = responseVariants.filter(variant => {
          variant.size = variant.title.split(" / ")[1]
          return variant.title.split(" / ")[0].toLowerCase() === item.color.toLowerCase()
        })
        setVariants(itemVariants)
        setVariantUpdate(itemVariants.find(v => v.title.split(" / ")[1] === item.size))
      })
  }

  useEffect(() => {
    if (sizeUpdate)
      setVariantUpdate(variants.find(v => v.title.split(" / ")[1] === sizeUpdate))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeUpdate])

  const moveItemToFavorites = () => {
    favorites.addItem(item.sku)
    setIsFavorite(true)
    favorites.getListSkus()
      .then(skus => {
        appState.set({
          favoritesSkus: skus,
        })
      })
    removeItem()
  }

  const updateItem = () => {
    cart.removeItem(item)
    // Update variantId
    // Update size
    // Update maxQuantity
    if (sizeUpdate) {
      item.variantId = parseInt(atob(variantUpdate.id).split("/")[4], 10)
      item.size = sizeUpdate
      item.maxQuantity = variantUpdate.quantityAvailable
    }
    // Update quantity
    if (quantityUpdate) {
      item.quantity = parseInt(quantityUpdate, 10)
      setQuantity(item.quantity)
    }
    cart.addItem(item)
    setCartItems(cart.getCart())
    updateCartCount()
    setShowEdit(false)
  }

  useEffect(() => {
    setIsFavorite(appState.favoritesSkus.includes(item.sku))
  }, [appState, setIsFavorite, item.sku])

  return (
    <li className={styles.item}>
      <Link
        to={`${item.url}${(item.url.includes("?")) ? "&" : "?"}origin=cart`}
        className={styles.imageLink}
      >
        <Image
          image={item.image}
          className={styles.image}
        />
      </Link>
      <div className={styles.content}>
        <h2 className={styles.title}>
          <Link to={item.url}>{item.title.split(" - ")[0]}</Link>
        </h2>

        {item.title !== "Digital Gift Card" &&
          <p className={styles.color}>{item.color} {item.title.split(" - ")[1]}</p>
        }

        {item.size &&
          <p className={styles.size}>{(item.size === "OS") ? item.size : `${item.size - 30} US / ${item.size} EU`}</p>
        }

        <div className={styles.quantityContainer}>
          <button
            className={styles.minus}
            onClick={decrementQuantity}
          >
            −
          </button>
          <input type="number"
                 className={styles.quantity}
                 value={quantity}
                 min="1"
                 pattern="[0-9]*"
                 onChange={changeQuantity}
          />
          <button
            className={styles.plus}
            onClick={incrementQuantity}
          >
            +
          </button>
        </div>

        {item.dateExpected &&
        <p className={styles.presaleNote}>Estimated to ship {new Date(item.dateExpected).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric"
        })}</p>
        }

        {item.saleType === "exchangeOnly" &&
        <p className={styles.offerRed}>
          This item is eligible for exchange only.
        </p>
        }

        {item.saleType === "finalSale" &&
        <p className={styles.offerRed}>
          This item is final sale.
        </p>
        }

        {!isFavorite &&
          <button
            className={styles.edit}
            onClick={moveItemToFavorites}
          >
            Move to Favorites
          </button>
        }
        <button
          className={styles.edit}
          onClick={removeItem}
        >
          Remove
        </button>
        <button
          className={styles.edit}
          onClick={editItem}
        >
          Edit
        </button>

        {showEdit &&
          <div className={styles.editContainer}>
            <select
              className={`${styles.editSelect} ${productStyles.sizeSelectBtn}`}
              value={sizeUpdate}
              onChange={e => setSizeUpdate(e.target.value)}
              onBlur={e => setSizeUpdate(e.target.value)}
            >
              <option value="">Size</option>
              {variants
                .sort((a, b) => Number(a.size) - Number(b.size))
                .map((variant, i) => (
                  <option
                    value={variant.size}
                    disabled={variant.quantityAvailable <= 0}
                    key={i}
                  >
                    {variant.size - 30} US / {variant.size} EU
                  </option>
                ))}
            </select>
            <select
              className={`${styles.editSelect} ${productStyles.sizeSelectBtn}`}
              value={quantityUpdate}
              onChange={e => setQuantityUpdate(e.target.value)}
              onBlur={e => setQuantityUpdate(e.target.value)}
            >
              <option value="">Quantity</option>
              {[1,2,3,4,5,6]
                .filter(quantity => quantity <= variantUpdate.quantityAvailable)
                .map((quantity, i) => (
                  <option
                    value={quantity}
                    key={i}
                  >
                    {quantity}
                  </option>
                ))}
            </select>
            <button
              className={`${styles.editBtn} ${buttonStyles.btnDark}`}
              onClick={updateItem}
            >
              Update
            </button>
            <button
              className={styles.edit}
              onClick={() => setShowEdit(false)}
            >
              Cancel
            </button>
          </div>
        }

        {item.isGwp &&
          <p className={styles.gwp}>Promotion will be applied at checkout.</p>
        }
      </div>

      <p className={styles.price}>
        <Price
          price={(quantity * parseInt(item.price, 10))}
          compareAtPrice={(item.compareAtPrice) ?
            (quantity * parseInt(item.compareAtPrice, 10))
            : null
          }
          // intlPrices={item.intlPrices}
          quantity={quantity}
        />
      </p>
    </li>
  )
}
