import React from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"

import styles from "./button.module.scss"


const Button = ({ color, outline, url, type, className, children }) => {
  const style = outline ? "Outline" : ""

  return (
    <>
      {type === "Link" && url.includes("http") &&
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles[`btn${color}${style}`]} ${className || ""}`}
        >
          {children}
        </a>
      }
      {type === "Link" && !url.includes("http") &&
        <Link
          to={url}
          className={`${styles[`btn${color}${style}`]} ${className || ""}`}
        >
          {children}
        </Link>
      }
      {type !== "Link" &&
        <button
          className={`${styles[`btn${color}${style}`]} ${className || ""}`}
        >
          {children}
        </button>
      }
    </>
  )
}

Button.propTypes = {
  color: PropTypes.string,
  outline: PropTypes.bool,
  url: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
}

Button.defaultProps = {
  color: "Dark",
  type: "Link",
}

export default Button

export const query = graphql`
  fragment ButtonFragment on ContentfulButtonLink {
    text
    type
    style
    outline
    color
    url
    action
  }
`
