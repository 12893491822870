import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Swiper from "react-id-swiper"

import Heading from "./Heading"
import Image from "./Image"

import styles from "./cartfavorites.module.scss"

import favorites from "../helpers/favorites"


const CartFavorites = ({ setCartItems }) => {
  const [favoritesRetrieved, setFavoritesRetrieved] = useState(false)
  const [favoritesList, setFavoritesList] = useState([])

  const [swiper, getSwiper] = useState(null)
  const [showPrevButton, setShowPrevButton] = useState(false)
  const [showNextButton, setShowNextButton] = useState(false)

  const params = {
    containerClass: `${styles.swiperContainer} swiper-container`,
    WrapperEl: "ul",
    getSwiper: getSwiper,
    watchOverflow: true,
    spaceBetween: 20,
    slidesPerView: 1.2,
    centerInsufficientSlides: true,
    breakpoints: {
      // when window width is >= 768px
      768: {
        spaceBetween: 20,
        slidesPerView: 3.3,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
      },
    },
    on: {
      init: function() {
        setShowPrevButton(!this.isBeginning)
        setShowNextButton(!this.isEnd)
      },
      transitionEnd: function() {
        setShowPrevButton(!this.isBeginning)
        setShowNextButton(!this.isEnd)
      }
    }
  }

  useEffect(() => {
    favorites.getListProducts()
      .then(({ data }) => {
        if (data.list)
          setFavoritesList(data.list)
        setFavoritesRetrieved(true)
      })
  }, [favoritesRetrieved])

  return(
    <>
      {favoritesList.length > 0 &&
        <div className={styles.favorites}>
          <Heading
            tag="h2"
            level="2"
            className={styles.heading}
          >
            Revisit Your Favorites
          </Heading>
          <Swiper {...params}>
            {favoritesList.map((favorite, i) => (
              <li
                key={i}
              >
                <Link
                  to={favorite.url.replace("https://www.tamaramellon.com","")}
                >
                  <Image
                    image={favorite.pdp[0]}
                    className={styles.image}
                  />
                </Link>
                <Heading
                  tag="h4"
                  level="4"
                  className={styles.productTitle}
                >
                  <Link
                    to={favorite.url.replace("https://www.tamaramellon.com","")}
                  >
                    {favorite.title.split(" - ")[0]}
                  </Link>
                </Heading>
                <p
                  className={styles.productPrice}
                >
                  ${favorite.price}
                </p>
              </li>
            ))}
          </Swiper>
          {showPrevButton &&
            <button
              className={`${styles.swiperButtonPrev} swiper-navigation-prev`}
              onClick={() => swiper.slidePrev()}
              aria-label="Previous"
            />
          }
          {showNextButton &&
            <button
              className={`${styles.swiperButtonNext} swiper-navigation-next`}
              onClick={() => swiper.slideNext()}
              aria-label="Next"
            />
          }
        </div>
      }
    </>
  )
}

export default CartFavorites
